// Vendors
import Vue from 'vue'
import 'bootstrap';
import 'jquery'

// Utilities
import router from './router'
import store from './store'
import '@/Features'

// Base App
import App from './AppContainer.vue'
import './assets/sass/app.scss'

import * as toastr from 'toastr';
import '@/utilities/flatMapPolyfill'

// Load these last, order is important
import '@/init/VueInit';
import {resetAllFeatureFlags} from '@/init/InitWindowCli';
import {appApi} from '@/init/ApiInit';
import '@/init/InitWindowCli';
import '@/init/VueInitWebOnly';

console.log(process.env)

// Global Event Listener
window['Events'] = new Vue({});
// Instantiate the App
new Vue({
    el : "#app",
    render: h => h(App),
    store,
    router
})
resetAllFeatureFlags()

export { router, store, appApi };

toastr.options.closeButton = true;
toastr.options.closeDuration = 5;
