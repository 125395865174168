import Vue from 'vue'
import Router from 'vue-router'

import Suggestions from '@/pages/SuggestionsViewController'
import Settings from '@/pages/Settings'
import TopicsManager from "@/pages/TopicsManager"
import CollectionsManager from "@/pages/CollectionsManager"
import GalleryBuilder from "@/pages/GalleryEdit"
import GalleryPreview from "@/components/Galleries/GalleryPreview"
import GalleryList from "@/components/Galleries/GalleryList"
import GalleryManager from "@/pages/GalleryManager"
import TeamManager from "@/pages/TeamManager"
import MobileLogin from "@/pages/MobileLogin"
import GalleryThemeEdit from '@/pages/GalleryThemeEdit'
import ContentTemplates from '@/pages/ContentTemplates'
import AdminPanel from '@/pages/AdminPanel'
import AddToCollection from '@/pages/embeds/AddToCollection'
import FilterViewManage from '@/pages/FilterViewManage'
import ComponentLibrary from '@/pages/ComponentLibrary'
// import QualityChecks from "@/pages/QualityChecks"

import ShowSuggestion from '@/pages/Suggestion'

import Login from "@/components/Auth/Login"
import SignUp from "@/components/Auth/SignUp"
import ForgotPassword from "@/components/Auth/ForgotPassword"
import ResetPassword from "@/components/Auth/ResetPassword"
import CreateAccountFromInvitation from "@/components/Auth/TeamInvite"

import OAuth from '@/components/Auth/ThirdParty/OAuth'

import QueryBuilder from "@/components/QueryBuilder/QueryPotter"

import NotesListDetailReader from '@/components/Notes/ModalReader/NotesViewController'
import AccountSetter from "@/components/Accounts/SetFromRoute"

import WelcomeScreen from "@/pages/WelcomeScreen"
import { store } from "@/main"
import { appApi } from "@/main"
import Playground from "../pages/Playground";
import SignUpFlowLayout from "@/components/Auth/V2/SignUpFlowLayout";

Vue.use(Router)

const appRouter = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            meta: {gtm: 'SuggestionsScreen'},
            name: 'Suggestions',
            component: Suggestions,
            beforeEnter : checkAuth,
        },
        {
            path: '/settings',
            meta: {gtm: 'SettingsScreen'},
            name: 'Settings',
            component : Settings,
            beforeEnter: checkAuth,
        },
        {
            path : '/signup',
            meta: {gtm: 'SignupScreen'},
            component : SignUp
        },
        {
            path: '/trial-signup',
            meta: {gtm: 'TrialSignupScreen'},
            name: 'Sign Up',
            component: SignUpFlowLayout,
            children: [
                {
                    path: '/trial-start',
                    meta: {gtm: 'TrialStartScreen'},
                    name: 'Start Trial',
                    component: SignUpFlowLayout,
                    // beforeEnter : checkAuth,
                },
            ],
        },
        {
            path: '/signin',
            meta: {gtm: 'SigninScreen'},
            component: Login
        },
        {
            path : '/sign-up',
            meta: { gtm: 'SigninScreen'},
            name : 'Sign Up',
            component : SignUp
        },
        {
            path: '/login',
            meta: {gtm: 'LoginScreen'},
            name: "Login",
            component : Login
        },
        {
            path: '/logout',
            meta: {gtm: 'LogoutScreen'},
            name: "Logout",
            beforeEnter : logout
        },
        {
            path: '/builder',
            meta: {gtm: 'BuilderScreen'},
            component : QueryBuilder
        },
        {
            path : '/welcome',
            meta: {gtm: 'WelcomeScreen'},
            name : "Welcome",
            component : WelcomeScreen,
            beforeEnter : checkAuth
        },
        {
            path : '/filter-view-manage',
            meta: {gtm: 'FilterViewManage'},
            name : "Manage Filter Views",
            component : FilterViewManage,
            beforeEnter : checkAuth
        },
        {
            path : '/playground',
            meta: {gtm: 'Playground'},
            name : "Playground",
            component : Playground,
            beforeEnter : checkAuth
        },
        {
            path : '/ui-components',
            meta: {gtm: 'ComponentLibrary'},
            name : "UiComponents",
            component : ComponentLibrary,
            beforeEnter : checkAuth
        },
        {
            path : '/forgot-password',
            meta: {gtm: 'ForgotPasswordScreen'},
            name : 'Forgot Password',
            component : ForgotPassword
        },
        {
            path : '/reset/:token',
            meta: {gtm: 'ResetPasswordScreen'},
            name : 'Reset Password',
            component : ResetPassword,
            props : true
        },
        {
            path : '/topics',
            meta: {gtm: 'TopicsManagerScreen'},
            name : "Topics Manager",
            component : TopicsManager,
            beforeEnter : checkAuth
        },
        {
            path: '/collections',
            meta: { gtm: 'CollectionsManagerScreen' },
            name: "Collections Manager",
            component: CollectionsManager,
            beforeEnter: checkAuth
        },
        {
            path: '/content-templates',
            meta: { gtm: 'ContentTemplatesScreen' },
            name: "Content Templates",
            component: ContentTemplates,
            beforeEnter: checkAuth
        },
        {
            path: '/adminxupc',
            meta: { gtm: 'AdminUpc' },
            name: "Admin Panel",
            component: AdminPanel,
            beforeEnter: checkAuth
        },
        // {
        //     path: '/quality-checks',
        //     meta: { gtm: 'QualityChecksScreen' },
        //     name: "Quality Checks",
        //     component: QualityChecks,
        //     beforeEnter: checkAuth
        // },

        {
            path: '/galleries',
            meta: { gtm: 'Galleries' },
            component: GalleryManager,
            beforeEnter: checkAuth,
            beforeRouteUpdate(){
                return;
            },
            onError(e) {
                console.log(e)
            },
            children: [
                {
                    path: '',
                    // NOTE(casio): this was causing a warning from the router which seems to be ok now
                    // TODO(all): could you please test if galleries work fine still? (havent used them yet)
                    name: "Gallery Manager",
                    meta: { gtm: 'Galleries' },
                    component: GalleryList
                },
                {
                    path: 'create',
                    meta: { gtm: 'Galleries' },
                    component: GalleryBuilder,

                },
                {
                    path: 'edit/:id',
                    props: true,
                    meta: { gtm: 'Galleries' },
                    component: GalleryBuilder
                },
                {
                    path: 'preview/:id',
                    props: true,
                    meta: { gtm: 'Galleries' },
                    component: GalleryPreview
                }
            ]
        },
        {
            path: '/gallery-theme-designer/:id',
            meta: { gtm: 'CollectionsManagerScreen' },
            name: "Gallery Theme Designer",
            component: GalleryThemeEdit,
            beforeEnter: checkAuth,
        },
        {
            path : '/team-members',
            meta: {gtm: 'TeamManagerScreen'},
            name : "Team Manager",
            component : TeamManager,
            beforeEnter : checkAuth
        },
        {
            path : '/invite/:iToken',
            meta: {gtm: 'AcceptInvitationScreen'},
            name : "Accept Invitation",
            props : true,
            component : CreateAccountFromInvitation
        },
        {
            path : '/suggestion/:suggestionId',
            meta: {gtm: 'SingleSuggestionScreen'},
            name : "Single Suggestion",
            props : true,
            component : ShowSuggestion
        },
        {
            path : '/notes',
            meta: {gtm: 'NotesScreen'},
            name : "Notes",
            component : NotesListDetailReader
        },
        {
            path : '/accounts/*/*',
            beforeEnter : (to, from, next) => {
                next('/');
            }
        },
        {
            path : '/accounts/:id',
            props : true,
            component : AccountSetter,
            beforeEnter : (to, from, next) => {

                let authed = (store.state.user && store.state.user.auth_token && store.state.user.user);
                let accountId = to.params.id;

                if (! authed){
                    next({ name: 'Login', query: { accountId } });
                } else {
                    next()
                }
            }
        },
        {
            path : '/mobile-login',
            name : "Mobile Login",
            meta: { gtm: 'MobileLoginScreen' },
            component: MobileLogin,
            beforeEnter: checkAuth
        },
        {
            path: '/add-to-collection',
            meta: {gtm: 'AddToCollection'},
            name: 'AddToCollection',
            component : AddToCollection,
            beforeEnter: checkAuth,
        },
        {
            path: '/apis/:apiName/authorize',
            props: true,
            name : "Authorize OAuth Code",
            meta : { gtm : "OAuth"},
            component : OAuth
        }
    ]
})

function checkAuth(to, from, next) {
    if (!store.state.user || !store.state.user.auth_token || !store.state.user.user) {
        next('/login')
    } else {
        appApi.post('/sessions/validation', {token : store.state.user.auth_token}).catch(e => {
            if (e.response && e.response.status > 400 && e.response.status < 500) {
                store.dispatch('logoutUser');
                store.dispatch('setLoginMessage', { message: "Your session has expired. Please log in again." });
                next('/login');
            }
        });
        next()
    }
}

function logout(to, from, next){
    store.dispatch('logoutUser')
    appApi.refreshCache();
    next('/login')
}

export default appRouter
