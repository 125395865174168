import router from "@/router";
import {isChromeExtension} from "@/services/Context";
import Vue from 'vue'

window.extensionData = Vue.observable({
    chrome: {
        tab: null
    }
})

export async function loadExtension() {
    try {
        if (isChromeExtension()) {
            await loadForChrome();
        }
    } catch {
        console.error(e)
    }
}

export function navigateToExtensionHome(pageUrl, title=null, publisher=null) {
    router.push(extensionHomeRoute(pageUrl, title, publisher))
}

export function extensionHomeRoute(pageUrl, title=null, publisher=null) {
    return addCollectionRoute(pageUrl, title, publisher)
}

export function addCollectionRoute(url=false, title=null, publisher=null) {
    return {
        path:'/add-to-collection',
        query: {
            ...(url ? {url} :{}),
            ...(title ? {title} :{}),
            ...(publisher ? {publisher} :{}),
            nav: "minimal",
            embed: true
        }
    };
}

export function chromeActiveTab() {
    return new Promise((resolve, reject) => {
        chrome.tabs.query({active: true, currentWindow: true}, tabs => {
            console.log('active tab found', tabs[0])
            resolve(tabs[0]);
        })
    })
}

console.log('BrowserExtension.js')

export function firefoxActiveTab() {
    return browser.tabs.query({currentWindow: true, active: true}).then(tabs => tab[0])
}

export async function safariActiveTab() {
    return safari.application.activeBrowserWindow.activeTab;
}

export async function loadExtensionForTab(tab) {
    try {
        window.$app.isExtension = true;
        window.$app.extension = {}
        window.navMode = 'minimal';
        navigateToExtensionHome();
        console.log('active tab found', tab);
        window.$app.extension.pageLocation = tab.url;

        let title = tab.title
        let publisher = '';
        let splitWords = [' | ', ' : ', ' - ']
        splitWords.forEach(x => {
            let parts = title && title.split(x)
            if (parts.length) title = parts[0]
            if (parts.length > 1) publisher = parts[1]
        })

        window.$app.extension.pageTitle = title;
        navigateToExtensionHome(tab.url, title, publisher);
    } catch(e) {
        console.error(e)
    }

}

export async function loadForChrome() {
    console.log('loadForChrome')
    window.$app.isChromeExtension = true;
    await loadExtensionForTab(await chromeActiveTab())

}
